import CoreTextProvider from "../CoreTextProvider"

export default class DefaultRuCoreTextProvider implements CoreTextProvider {
  roundingDecimalsCount(): string {
    return "Количество знаков после запятой"
  }

  calculationType(): string {
    return "Тип расчета"
  }

  calculationTypeSubtractPercentage(): string {
    return "В процентах"
  }

  calculationTypeSubtractFixed(): string {
    return "Фиксированное значение"
  }

  calculationTypeFixed(): string {
    return "Фиксированное значение"
  }

  calculationTypeFulfillmentProvider(): string {
    return "Через службу доставки"
  }

  discountRules(): string {
    return "Правила скидок"
  }

  discountRule(): string {
    return "Правило скидки"
  }

  value(): string {
    return "Значение"
  }

  newDiscountRule(): string {
    return "Новое правило скидки"
  }

  priority(): string {
    return "Приоритет применения правила"
  }

  variant({
    number
  }: {
    readonly number: number
  }): string {
    return `Вариант ${number}`
  }

  optionValues(): string {
    return "Значения опции"
  }

  optionValue(): string {
    return "Значение опции"
  }

  pickUpPoint(): string {
    return "Пункт самовывоза"
  }

  pickUp(): string {
    return "Самовывоз"
  }

  delivery(): string {
    return "Доставка"
  }

  orderReceivingPriceRuleConditionTypeOrderAmountLessThanOrEqualsTo(): string {
    return "Сумма заказа <="
  }

  orderReceivingPriceRuleConditionTypeOrderAmountGreaterThan(): string {
    return "Сумма заказа >"
  }

  orderReceivingPriceRuleConditionTypeOrderAmountGreaterThanOrEqualsTo(): string {
    return "Сумма заказа >="
  }

  orderReceivingPriceRuleConditionTypeOrderAmountLessThan(): string {
    return "Сумма заказа <"
  }

  type(): string {
    return "Тип"
  }

  fulfillmentProviderType(): string {
    return "Служба доставки"
  }

  newPlace(): string {
    return "Новое место"
  }

  places(): string {
    return "Места"
  }

  blockHeaderLeftContent(): string {
    return "Левая часть шапки"
  }

  blockHeaderRightContent(): string {
    return "Правая часть шапки"
  }

  blockHeaderCenterContent(): string {
    return "Центральная часть шапки"
  }

  logo(): string {
    return "Логотип"
  }

  place(): string {
    return "Место"
  }

  blockHeader(): string {
    return "Шапка"
  }

  blockLoyaltyProgram(): string {
    return "Информация о программе лояльности"
  }

  blockProducts(): string {
    return "Товары"
  }

  add(): string {
    return "Добавить"
  }

  blockProductCategories(): string {
    return "Категорий товаров"
  }

  blockColumnsCount(): string {
    return "Количество в строке"
  }

  url(): string {
    return "Ссылка"
  }

  blockBanners(): string {
    return "Баннеры"
  }

  blockViewTypePager(): string {
    return "Слайдер с фиксацией страниц"
  }

  blockViewTypeSlider(): string {
    return "Слайдер"
  }

  blockViewTypeList(): string {
    return "Вертикальный список"
  }

  banners(): string {
    return "Баннеры"
  }

  bannerWithNumber({ number }: { readonly number: number }): string {
    return `Баннер ${number}`
  }

  blockCurrentOrders(): string {
    return "Текущие заказы"
  }

  blockSearch(): string {
    return "Строка поиска"
  }

  blocks(): string {
    return "Блоки"
  }

  blockViewType(): string {
    return "Тип отображения"
  }

  blockWithNumber({ number }: { readonly number: number }): string {
    return `Блок ${number}`
  }

  blockType(): string {
    return "Тип блока"
  }

  blockMenu(): string {
    return "Меню"
  }

  menuItemWithNumber(parameters: { readonly number: number }): string {
    return `Пункт меню ${parameters.number}`
  }

  title(): string {
    return "Заголовок"
  }

  dashboard(): string {
    return "Экран"
  }

  bonusCard(): string {
    return "Карта клиента"
  }

  dashboards(): string {
    return "Экраны"
  }

  image(): string {
    return "Изображение"
  }

  mainImage(): string {
    return "Главное"
  }

  addImage(): string {
    return "Добавить изображение"
  }

  variantImages(): string {
    return "Изображения варианта"
  }

  mainVariantImages(): string {
    return "Изображения товара"
  }

  variants(): string {
    return "Варианты"
  }

  position(): string {
    return "Позиция"
  }

  id(): string {
    return "ID"
  }

  code(): string {
    return "Код"
  }

  name(): string {
    return "Название"
  }

  nameForUser(): string {
    return "Имя для пользователя"
  }

  displayStyle(): string {
    return "Стиль отображения"
  }

  displayStyleTags(): string {
    return "Теги"
  }

  displayStyleRadioButtons(): string {
    return "Радио кнопки"
  }

  product(): string {
    return "Товар"
  }

  products(): string {
    return "Товары"
  }

  recipient(): string {
    return "Получатель"
  }

  productWithNumber({ number }: { readonly number: number }): string {
    return `Товар ${number}`
  }

  newProduct(): string {
    return "Новый товар"
  }

  productsSetConditions(): string {
    return "Условия подборки товаров"
  }

  productsSetConditionWithNumber({ number }: { readonly number: number }): string {
    return `Условие ${number}`
  }

  productsSetWithNumber({ number }: { readonly number: number }): string {
    return `Подборка товаров ${number}`
  }

  productsSetConditionType(): string {
    return "Тип условия"
  }

  productsSetConditionTypeProductCategory(): string {
    return "Категория товаров"
  }

  productsSetConditionTypeProducts(): string {
    return "Товары"
  }

  productsSetConditionTypeProductsCanBeOrderedWithBonusPoints(): string {
    return "Можно купить за бонусные баллы"
  }

  productCategory(): string {
    return "Категория товаров"
  }

  productCategories(): string {
    return "Категории товаров"
  }

  productCategoryWithNumber({ number }: { readonly number: number }): string {
    return `Категория товаров ${number}`
  }

  newProductCategory(): string {
    return "Новая категория товаров"
  }

  price(): string {
    return "Цена"
  }

  originalPrice(): string {
    return "Оригинальная цена"
  }

  bonusPointsPrice(): string {
    return "Цена в бонусных баллах"
  }

  description(): string {
    return "Описание"
  }

  requestBody(): string {
    return "Тело запроса"
  }

  responseBody(): string {
    return "Тело ответа"
  }

  tags(): string {
    return "Тэги"
  }

  text(): string {
    return "Текст"
  }

  selectDate(): string {
    return "Выберите дату"
  }

  options(): string {
    return "Опции"
  }

  newOption(): string {
    return "Новая опция"
  }

  option(): string {
    return "Опция"
  }

  orderProcessingStatuses(): string {
    return "Статусы обработки заказа"
  }

  newOrderProcessingStatus(): string {
    return "Новый Статус обработки заказа"
  }

  orderProcessingStatus(): string {
    return "Статус обработки заказа"
  }

  number(): string {
    return "Номер"
  }

  orders(): string {
    return "Заказы"
  }

  order(): string {
    return "Заказ"
  }

  comment(): string {
    return "Комментарий"
  }

  orderedAt(): string {
    return "Дата оформления"
  }

  processingStatus(): string {
    return "Статус обработки"
  }

  receivingMethod(): string {
    return "Способ получения"
  }

  paymentMethod(): string {
    return "Способ оплаты"
  }

  client(): string {
    return "Покупатель"
  }

  firstName(): string {
    return "Имя"
  }

  lastName(): string {
    return "Фамилия"
  }

  count(): string {
    return "Количество"
  }

  dataType(): string {
    return "Тип данных"
  }

  externalCode(): string {
    return "Внешний код"
  }

  newProperty(): string {
    return "Новое свойство"
  }

  properties(): string {
    return "Свойства"
  }

  property(): string {
    return "Свойство"
  }

  propertyValue(): string {
    return "Значение свойства"
  }

  propertyValues(): string {
    return "Значения свойства"
  }

  uom(): string {
    return "Единица измерения"
  }

  productsSetConditionTypePropertiesValues(): string {
    return "Значения свойств"
  }

  propertiesValues(): string {
    return "Значения свойств"
  }

  propertyWithNumber({
    number
  }: {
    readonly number: number
  }): string {
    return `Свойство ${number}`
  }

  values(): string {
    return "Значения"
  }

  bonusProgramLevels(): string {
    return "Уровни бонусной программы"
  }

  bonusProgramLevel(): string {
    return "Уровень бонусной программы"
  }

  bonusPointsBurningRule(): string {
    return "Правило сгорания бонусных баллов"
  }

  newBonusProgramLevel(): string {
    return "Новый уровень бонусной программы"
  }

  bonusPointsPercent(): string {
    return "Процент бонусов"
  }

  bonusPointsCount(): string {
    return "Количество бонусов"
  }

  pricePercentBonusPointsCalculationType(): string {
    return "Проценты от цены"
  }

  fixedBonusPointsCalculationType(): string {
    return "Фиксированное количество бонусов"
  }

  clients(): string {
    return "Покупатели"
  }

  fullName(): string {
    return "ФИО"
  }

  phoneNumber(): string {
    return "Номер телефона"
  }

  bonusPoints(): string {
    return "Накопленных бонусов"
  }

  bonusPointsAccrual(): string {
    return "Начисление бонусных баллов"
  }

  bonusPointsRule({ number }: { readonly number: number }): string {
    return `Правило ${number}`
  }

  emailAddress(): string {
    return "Почта"
  }

  address(): string {
    return "Адрес"
  }

  admin(): string {
    return "Администратор"
  }

  admins(): string {
    return "Администраторы"
  }

  newAdmin(): string {
    return "Новый администратор"
  }

  paymentStatuses(): string {
    return "Статусы оплаты"
  }

  paymentStatus(): string {
    return "Статус оплаты"
  }

  newPaymentStatus(): string {
    return "Новый статус оплаты"
  }

  imageViewTypeDetails(): string {
    return "Вид в карточке"
  }

  imageViewTypeFullScreen(): string {
    return "Вид на весь экран"
  }

  imageViewTypeList(): string {
    return "Вид в списке"
  }

  password(): string {
    return "Пароль"
  }

  passwordConfirmation(): string {
    return "Подтверждение пароля"
  }

  emailNotificationsEnabled(): string {
    return "Присылать оповещения на почту"
  }

  hasBatches(): string {
    return "Есть серии"
  }

  batches(): string {
    return "Серии"
  }

  batch({ number }: { readonly number: number }): string {
    return `Серия ${number}`
  }

  batchPlaceIsAvailable(): string {
    return "Доступно"
  }

  isInProcessing(): string {
    return "Отображать в текущих заказах"
  }

  isOrderProcessingStatusCompleted(): string {
    return "Завершен"
  }

  isOrderProcessingStatusCreated(): string {
    return "Начальный"
  }

  isOrderProcessingStatusCanceled(): string {
    return "Отменен"
  }

  inputMultiplier(): string {
    return "Кратность в заказе"
  }

  excludingIsArchivedStatus(): string {
    return "В архиве, Не в архиве"
  }

  isArchived(): string {
    return "В архиве"
  }

  isNotArchived(): string {
    return "Не в архиве"
  }

  newDashboard(): string {
    return "Новый экран"
  }

  link(): string {
    return "Ссылка"
  }

  displayInProduct(): string {
    return "Отображать в информации о товаре"
  }

  displayInFilters(): string {
    return "Отображать в списке фильтров для списка товаров"
  }

  orderReceivingMethods(): string {
    return "Способы получения заказа"
  }

  newOrderReceivingMethod(): string {
    return "Новый способ получения заказа"
  }

  orderReceivingMethod(): string {
    return "Способ получения заказа"
  }

  isDefaultInCheckout(): string {
    return "Предвыбор в оформлении заказа"
  }

  isAvailableInCheckout(): string {
    return "Доступно при оформлении заказа"
  }

  fulfillmentProviderTypeInternal(): string {
    return "Своя"
  }

  fulfillmentProviderTypeCdek(): string {
    return "СДЭК"
  }

  orderReceivingPriceRules(): string {
    return "Правила расчета стоимости"
  }

  orderReceivingPriceRule({ number }: { readonly number: number }): string {
    return `Правило ${number}`
  }

  orderReceivingPriceRuleConditions(): string {
    return "Условия"
  }

  orderReceivingPriceRuleCondition({ number }: { number: number }): string {
    return `Условие ${number}`
  }

  orderShippingMethodType(): string {
    return "Способ отправки заказа"
  }

  orderShippingMethodTypeReceivingPoint(): string {
    return "Принести в пункт отправки"
  }

  orderShippingMethodTypeDelivery(): string {
    return "Вызвать курьера"
  }

  orderShippingMethodSettlement(): string {
    return "Населенный пункт отправки заказа"
  }

  settlement(): string {
    return "Населенный пункт"
  }

  orderShippingTariff(): string {
    return "Тариф отправки заказа"
  }

  canBeOrderedWithBonusPoints(): string {
    return "Можно купить за бонусные баллы"
  }

  bonusPointsBurningPeriod(): string {
    return "Период сгорания бонусных баллов"
  }

  monthly(): string {
    return "Ежемесячно"
  }

  minEarnedSum(): string {
    return "Минимальная сумма накопленных бонусных баллов"
  }

  bonusProgramLevelTransitionRule(): string {
    return "Правило перехода на уровень"
  }

  bonusProgramLevelTransitionRuleMonthlyPeriodType(): string {
    return "Каждый месяц"
  }

  bonusProgramLevelTransitionRulePeriodicBuyType(): string {
    return "Периодическая покупка товаров"
  }

  period(): string {
    return "Период"
  }

  bonusProgramLevelTransitionRulePeriodsCount(): string {
    return "В течении"
  }

  bonusProgramLevelTransitionRuleCheckingValueType(): string {
    return "Проверяемое значение"
  }

  orderItemsQuantity(): string {
    return "Количество купленных товаров"
  }

  orderPaymentMethods(): string {
    return "Способы оплаты"
  }

  newOrderPaymentMethod(): string {
    return "Новый способ оплаты"
  }

  orderPaymentMethod(): string {
    return "Способ оплаты"
  }

  paymentMethodTypeOffline(): string {
    return "Оффлайн"
  }

  paymentMethodTypeBankCard(): string {
    return "Банковская карта"
  }

  paymentMethodTypeSbp(): string {
    return "Система Быстрых Платежей"
  }

  paymentMethodTypeYooMoney(): string {
    return "ЮMoney"
  }

  paymentMethodTypeSberPay(): string {
    return "SberPay"
  }

  paymentMethodTypeTPay(): string {
    return "T-Pay"
  }

  paymentMethodTypeDolyame(): string {
    return "Долями"
  }

  provider(): string {
    return "Провайдер"
  }

  availableForOrderReceivingMethods(): string {
    return "Доступно для способов получения заказа"
  }

  smsAeroServiceType(): string {
    return "sms Aero"
  }

  yookassaServiceType(): string {
    return "ЮКасса"
  }

  cdekServiceType(): string {
    return "СДЭК"
  }

  dolyameServiceType(): string {
    return "Долями"
  }

  daDataServiceType(): string {
    return "DaData"
  }

  synchronizationServiceType(): string {
    return "Синхронизация"
  }

  logItems(): string {
    return "Логи"
  }

  logItem(): string {
    return "Лог"
  }

  service(): string {
    return "Сервис"
  }

  direction(): string {
    return "Направление"
  }

  incoming(): string {
    return "Входящий"
  }

  outgoing(): string {
    return "Исходящий"
  }

  finishedAt(): string {
    return "Время окончания"
  }

  startedAt(): string {
    return "Время начала"
  }

  requestType(): string {
    return "Тип запроса"
  }

  httpStatus(): string {
    return "HTTP-статус"
  }

  resultType(): string {
    return "Тип результата"
  }

  unknown(): string {
    return "Неизвестный статус"
  }

  success(): string {
    return "Успех"
  }

  missing(): string {
    return "Статус не получен"
  }

  serverError(): string {
    return "Ошибка сервера"
  }

  redirect(): string {
    return "Перенаправление"
  }

  clientError(): string {
    return "Ошибка клиента"
  }

  orderSourceType(): string {
    return "Источник заказа"
  }

  orderSourceTypeOnline(): string {
    return "Онлайн заказ"
  }

  orderSourceTypeOffline(): string {
    return "Оффлайн заказ"
  }

  useForOfflineSale(): string {
    return "Использовать для оффлайн продажи"
  }

  longitude(): string {
    return "Долгота"
  }

  latitude(): string {
    return "Широта"
  }
}
